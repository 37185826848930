<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h2 class="white--text">Εργαλείο Αφαίρεσης Χαρακτήρων</h2>
      </div>

      <v-spacer></v-spacer>
      <v-img
          alt="CompSoft Μηχανογράφηση Επιχειρήσεων"
          class="shrink pa-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/Compsoft_logo.png"
          width="130"
      />
    </v-app-bar>

    <v-main>
      <ComputeForm/>
    </v-main>
  </v-app>
</template>

<script>
import ComputeForm from './components/ComputeForm';

export default {
  name: 'App',

  components: {
    ComputeForm,
  },

  data: () => ({
    //
  }),
};
</script>
