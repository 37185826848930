import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.red,
                secondary: colors.red.accent1,
                accent: colors.blue.accent1,
                error: '#b71c1c',
            }
        }
    }
});