<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">

        <div class="ma-8">
            <v-row>
                <v-col>
                    <v-file-input
                        label="Φόρτωσε πίνακα"
                        outlined
                        clearable
                        v-model="file"
                        v-on:change="loadContents"
                    ></v-file-input>
                </v-col>
                <v-col>
                    <v-file-input
                        label="Φόρτωσε αρχείο παραμέτρων"
                        outlined
                        clearable
                        v-model="configFile"
                        v-on:change="loadConfig"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="select"
                  item-text="type"
                  item-value="positions"
                  :items="config"
                  label="Τύπος πίνακα"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  label="Θέσεις"
                  outlined
                  disabled
                  v-model="positionsInput"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <v-checkbox v-model="commasToDots" label="Κόμματα σε τελείες"></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox v-model="dryRun" label="Δοκιμαστική λειτουργία"></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row align="end">
            <v-col>
              <v-btn class="ma-2" :disabled="!fileContents || !select" :loading="buttonLoading" v-on:click="transformFile">ΜΕΤΑΤΡΟΠΗ</v-btn>
              <!-- <v-btn class="ma-2"  v-on:click="downloadTransformed">ΚΑΤΕΒΑΣΜΑ</v-btn> -->
              <v-btn class="ma-2" :disabled="!fileContents" v-on:click="downloadTransformed">ΚΑΤΕΒΑΣΜΑ</v-btn>
            </v-col>
            </v-row>
        </div>
        <div v-if="file !== null" id="textAreaBox">
          <v-textarea
              no-resize
              readonly
              placeholder=""
              v-model="fileContents"
              wrap="soft"
          />
          <!-- TODO: v-model should not use prop directly. -->
          <!-- TODO: "outlined" for v-textarea, removed for now due to
          strange resizing of div when enabled. Could be vuetify bug. -->
          <!-- <v-btn class="ma-2" v-on:click="magic">MAGIC</v-btn> -->
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {saveAs} from 'file-saver';
var iconv = require('iconv-lite');

/*
  https://en.wikipedia.org/wiki/Code_page_737
  Don't even get me started.
 */
function toMSDOSGreek(text) {
  console.log(text);
  let asciiCodes = text.split('').map(c => c.charCodeAt());
  console.log(asciiCodes);
  asciiCodes = asciiCodes.map((charCode) => {
    if (charCode > 255) {
      /*
        Map 913 -> 937 to 128 -> 151
       */
      if (charCode > 930) {
        return charCode - 786;
      }
      return charCode - 785;
    }
    else {
      return charCode;
    }
  });
  return asciiCodes;
}

function columnJoin(fileContents, positions, dryRun, commasToDots) {
  // https://stackoverflow.com/a/5035058/7151170
  let fileLines = fileContents.match(/[^\r\n]+/g);
  if (!dryRun) {
    fileLines.shift();
  }
  let transformed = fileLines.map(line => {
    // console.log(line);
    let chars = line.split('');
    // console.log(chars);
    for (let pos of positions) {
      chars[pos-1] = '~';
    }
    // console.log(chars);
    // console.log(line);
    let newLine = chars.join("");
    if (dryRun) {
      return newLine;      
    }
    else {
      if (commasToDots) {
        newLine = newLine.replace(/,/g, '.');        
      }
      return newLine.replace(/~/g, '');
    }
  });
  return transformed.join('\r\n');
}

export default {
  name: 'ComputeForm',
  methods: {
    transformFile() {
      this.buttonLoading = true;
      if (this.fileContents && this.select) {
        // console.log("Transforming...");
        // console.log(this.fileContents);
        // console.log(this.positions);
        this.fileContents = columnJoin(this.fileContents, this.select, this.dryRun, this.commasToDots);
      }
      this.buttonLoading = false;
      this.buttonDisabled = true;
      return;
    },
    loadContents() {
      if (this.file) {
          if (!Array.isArray(this.file)) {
              var reader = new FileReader();
              reader.readAsText(this.file);
              reader.onload = () => {
                  this.fileContents = reader.result;
              };
          } else {
              console.log("Currently only single files are supported.");
          }
          this.buttonDisabled = false;
      } else {
          console.log("No files selected.");
          this.fileContents = ""
          this.buttonDisabled = true;
      }
    },
    loadConfig() {
      if (this.configFile) {
        if (!Array.isArray(this.configFile)) {
          var reader = new FileReader();
          reader.readAsText(this.configFile);
          reader.onload = () => {
            let configContents = reader.result;
            console.log(configContents);
            let configArray = configContents.match(/[^\r\n]+/g);
            console.log(configArray);
            let keys = configArray.filter((v,i) => !(i%2));
            console.log(keys);
            let vals = configArray.filter((v,i) => i%2).map(x => x.split(',').map(Number));
            console.log(vals);
            let array = [];
            for (const i in keys) {
              console.log(keys[i]);
              array.push({"type": keys[i], "positions": vals[i]});
            }
            this.config = array;
          };
        } else {
            console.log("Currently only single files are supported.");
        }
        this.buttonDisabled = false;
      } else {
          console.log("No config files selected.");
          this.buttonDisabled = true;
      }
    },
    downloadTransformed() {
      // console.log("Type:");
      // console.log(typeof this.fileContents);
      let buf = iconv.encode(this.fileContents, 'win1253');
      // console.log(buf);
      // let asciiCodes = toMSDOSGreek(this.fileContents);
      // console.log(asciiCodes);
      // var asciiBytes = Uint8Array.from(asciiCodes);
      // console.log(asciiBytes);
      // let blob = new Blob(asciiBytes, {type: "example/binary"});
      // console.log(blob);
      // saveAs(blob, this.file.name);
      // var
      //   buffer = new ArrayBuffer(asciiCodes.length) // allocates 8 bytes
      // , data = new DataView(buffer)
      // ;
      // You can write uint8/16/32s and float32/64s to dataviews
      // console.log(asciiCodes);
      // for (const i in asciiCodes) {
        // console.log(`Setting byte ${i} to ${asciiCodes[i]}`);
      //   data.setUint8(i, asciiCodes[i]);
      // }
      // console.log(data);
      saveAs(new Blob([buf], {type: "example/binary"}), this.file.name);
      return;
    },
  },
  data: () => ({
    buttonLoading: false,
    buttonDisabled: true,
    file: null,
    fileContents: null,
    transformedContents: null,
    configFile: null,
    config: [],
    select: null,
    dryRun: false,
    commasToDots: false,
  }),
  computed: {
    // () => {} is not good for computed. Minutes wasted: 30.
    positions: function () {
      if (this.positionsInput === null || this.positionsInput == "") {
        return null;
      } else {
        return this.positionsInput.replace(/\s+/g, '').split(',').map(Number);
      }
    },
    positionsInput: function () {
      console.log("Tried");
      console.log(this.select);
      if (this.select != null) {
        return this.select;
      }
      console.log("But failed");
      return null;
    }
  }
}
</script>

<style scoped>
#textAreaBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* border: 1px solid #cccccc; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
}

#textAreaBox .v-text-field {
    font-size: 0.8em;
    font-family: 'Courier New', Courier, monospace;
    line-height: 80%;
}
</style>

<style>
#textAreaBox textarea {
  white-space: pre;
  height: 200px;
}
</style>